import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate, Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import useBreakpoint from '../../Hooks/useBreakPoint';
import Carousel from '../../Components/CarouselWrapper';
import {LocationOn} from '@styled-icons/material';
import {TravelBus} from '@styled-icons/zondicons';
import {Car} from '@styled-icons/boxicons-solid';

let TAIPEI_MOCK = [
  '../../images/taipei-1.jpg',
  '../../images/taipei-2.jpg',
  '../../images/taipei-3.jpg',
  '../../images/taipei-4.jpg',
  '../../images/taipei-5.jpg',
  '../../images/taipei-6.jpg',
];

let TAICHUNG_MOCK = [
  '../../images/taichung-1.jpg',
  '../../images/taichung-2.jpg',
  '../../images/taichung-3.jpg',
  '../../images/taichung-4.jpg',
  '../../images/taichung-5.jpg',
  '../../images/taichung-6.jpg',
];

function About(props) {
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const [articles, setArticles] = React.useState([]);
  const [banner, setBanner] = React.useState('');

  const [bannerDimension, setBannerDimension] = React.useState(null);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem?.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  React.useEffect(() => {
    async function fetchArticleData() {
      try {
        actions.setLoading(true);
        let resp = await actions.fetchDocuments(
          'Article_Default',
          {
            label: {$all: ['公益活動']},
          },
          ['-created'],
        );
        let dataList = resp.results.map((it) => {
          if (!it.id && it._id) {
            it.id = it._id.$oid;
          }
          return {...it};
        });
        setArticles(dataList);
      } catch (ex) {
        console.warn(ex);
      } finally {
        actions.setLoading(false);
      }
    }
    async function fetchBanner() {
      actions.setLoading(true);
      let bannerList = await actions.fetchDocuments('banner');
      bannerList = bannerList.results;
      let _banner = await bannerList.filter(
        (banner) => banner.type === 'about',
      )[0];
      actions.setLoading(false);
      setBanner(_banner.image);
    }
    fetchBanner();

    fetchArticleData();
  }, [actions]);

  let isMobile = dimension.rwd !== 'desktop';

  return (
    <Wrapper>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          alt="about_banner"
          src={banner}
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section">
        <div className="subtitle">CORE VALUE</div>
        <div className="title">品牌核心</div>
        <div className="carousel-wrapper" style={{marginTop: 20}}>
          <div className="content primary-color">
            {`美麗的最高境界
是讓內在心靈及外觀樣貌，同時達到最美的平衡狀態`}{' '}
          </div>
          {dimension.rwd !== 'desktop' && (
            <p style={{whiteSpace: 'pre-line', marginTop: 30}}>
              {`醫美不再只是治療，而是一種探尋自我的體驗

微媞期待在這段體驗的過程中
成為你的美麗導師，也成為你的美麗閨蜜
幫助您實現對美的嚮往，也陪著你探索自信

由內而外，不論是內心或外表
都呈現出最美的樣貌，成為更好的自己。`}
            </p>
          )}
          <img
            alt="brand_banner"
            src={
              dimension.rwd === 'desktop'
                ? '../../images/about1.jpg'
                : '../../images/about-s.jpg'
            }
            style={{
              objectFit: 'cover',
              flex: 1,
              marginTop: 50,
              width: '100%',
              // height: dimension.rwd === "desktop" ? 500 : 200,
              borderRadius: 10,
            }}
          />
        </div>
      </section>
      <section style={{backgroundColor: '#f6f6f6'}}>
        <div className={`constraint ${!isMobile ? 'row' : ''}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flex: 1,
              padding: 30,
              borderRadius: !isMobile ? '30px 0px 0px 30px' : 0,
              backgroundColor: '#fff',
            }}>
            <div className="title primary-color">微媞時尚診所 台北院</div>
            <p
              className="content"
              style={{
                marginTop: 25,
              }}>{`微媞時尚集團於2011年成立–台北微媞時尚診所，從膚質管理、老化容貌改善、五官立體調整，到身形曲線雕塑等，運用全方位的專業美感規劃，客製化的專屬服務及平實的價格，讓每位客戶都能獲得超值有感的美學體驗。為客戶找回自信是我們始終如一的目標。選擇微媞，美麗不再遙遠。`}</p>
            <p
              className="content"
              style={{
                marginTop: 25,
              }}>{`服務專線 (02) 2721-9663
診所位置 106台北市大安區忠孝東路四段250號2樓
營業時間 週一到五 12:00至21:00 週六11:00至20:00
		`}</p>
          </div>
          <div
            style={{
              flex: 1,
              borderRadius: !isMobile ? '0px 30px 30px 0px' : 0,
              overflow: 'hidden',
            }}>
            <Carousel
              width={isMobile ? dimension?.innerWidth - 40 : 1240 / 2}
              height={isMobile ? dimension?.innerWidth / 1.5 : 1240 / 2.6}
              items={TAIPEI_MOCK}
            />
          </div>
        </div>
      </section>
      <section>
        <div className={`constraint ${!isMobile ? 'row' : ''}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flex: 1,
              padding: 30,
              borderRadius: !isMobile ? '30px 0px 0px 30px' : 0,
              backgroundColor: '#fff',
            }}>
            <div className="title primary-color">微媞時尚診所 台中院</div>
            <p
              className="content"
              style={{
                marginTop: 25,
              }}>{`「青春美麗」是人們對於美的永恆需求，為擴大品牌價值，滿足更多美的需要，微媞時尚集團於2014年成立–台中微媞時尚診所。延續品牌理念，以精緻親切的客戶服務為訴求，提供客戶全方位的醫美服務與完善醫療品質，讓美麗不斷延續。`}</p>
            <p
              className="content"
              style={{
                marginTop: 25,
              }}>{`服務專線 (04)2328-8880
診所位置 408台中市南屯區向上路一段658號3樓
營業時間 週一到四 12:00至21:00 週五六11:00至20:00
		`}</p>
          </div>
          <div
            style={{
              flex: 1,
              borderRadius: !isMobile ? '0px 30px 30px 0px' : 0,
              overflow: 'hidden',
            }}>
            <Carousel
              width={isMobile ? dimension?.innerWidth - 40 : 1240 / 2}
              height={isMobile ? dimension?.innerWidth / 1.5 : 1240 / 2.6}
              items={TAICHUNG_MOCK}
            />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="subtitle">CHARITY</div>
        <div className="title">公益活動</div>
        <section className="grid" style={{flexDirection: 'column'}}>
          {articles.map((item, idx) => (
            <div
              onClick={() => {
                navigate(`/about-post?id=${item.id}`);
              }}
              className="grid-item-large"
              key={idx}>
              <div
                className="grid-item-img-large"
                style={{backgroundImage: `url(${item.image})`}}
              />
              <div className="grid-context-container">
                <p className="grid-item-date">
                  {new Date(item.created).toLocaleDateString('en-ZA')}
                </p>
                <p className="grid-item-title">{item.title}</p>
                <p
                  className="grid-item-outline outline"
                  style={{marginTop: 10}}>
                  {item.outline}
                </p>
              </div>
            </div>
          ))}
        </section>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .banner {
    width: 100%;
    height: 600px;
    /* position: relative; */
    /* background-color: var(--primaryColor); */
  }
`;

export default About;
